<template>
  <q-layout view="lhh LpR lff" container>
    <q-header class="transparent">
      <div
        class="q-mb-md q-mx-lg"
        :style="`margin-top: ${$q.screen.lt.md ? 10 : 27}px`"
      >
        <span>{{ t("name") }}</span> / <br />{{ t("title") }}
      </div>
      <q-tabs
        v-model="tab"
        active-color="primary"
        class="text-white"
        indicator-color="primary"
        narrow-indicator
        no-caps
        size="sm"
      >
        <q-tab name="work" label="Work" />
        <q-tab name="cv" label="CV" />
        <q-tab name="contact" label="Contact" />
      </q-tabs>
    </q-header>
    <q-page-container>
      <q-page>
        <q-scroll-area class="absolute-full mask-overflow-tb-drawer">
          <q-tab-panels v-model="tab" animated>
            <q-tab-panel name="work">
              <work-items />
            </q-tab-panel>
            <q-tab-panel name="cv">
              <cv-items />
            </q-tab-panel>
            <q-tab-panel name="contact">
              <q-list dense padding>
                <q-item class="text-caption text-dark" header>{{
                  t("contact.cell")
                }}</q-item>
                <q-item class="text-caption text-dark" header
                  >chris@quezada.work</q-item
                >
              </q-list>
            </q-tab-panel>
          </q-tab-panels>
        </q-scroll-area>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import { ref } from "vue";
import { i18n } from "src/boot/i18n";
import CvItems from "../cv/CvItems.vue";
import WorkItems from "../work/WorkItems.vue";

defineOptions({ name: "DrawerTabs" });

const { t } = i18n.global;
const tab = ref("work");
</script>
