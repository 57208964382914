<template>
  <div class="bg-dark fullscreen flex flex-center z-max">
    <q-responsive
      :ratio="$q.screen.lt.md ? 0.7 : 1"
      style="width: 700px; max-width: 80vw; overflow: visible"
    >
      <div
        class="atropos cursor-pointer"
        ref="atroposRef"
        style="overflow: visible"
        @click="onClick()"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
      >
        <div class="atropos-scale" style="overflow: visible">
          <div class="atropos-rotate" style="overflow: visible">
            <div class="atropos-inner" style="overflow: visible">
              <div class="fit relative-position" style="overflow: visible">
                <q-card
                  class="absolute-full flex flex-center"
                  flat
                  style="overflow: visible"
                >
                  <q-card-section>
                    <div class="row">
                      <div
                        class="col-12 q-mx-auto"
                        :style="`width: 500px; max-width: ${
                          $q.screen.lt.md ? 6 : 4
                        }0vw`"
                      >
                        <h1 :class="$q.screen.lt.sm ? 'text-h3' : 'text-h1'">
                          {{ t("title") }}
                        </h1>
                      </div>
                      <div
                        class="col-12 justify-between q-mx-auto q-my-xl row"
                        :style="`width: 500px; max-width: ${
                          $q.screen.lt.md ? 6 : 4
                        }0vw`"
                      >
                        <div
                          class="text-caption text-left"
                          data-atropos-offset="1"
                          style="line-height: 1.3125"
                        >
                          <span class="text-weight-bold">{{ t("name") }}</span>
                          <br />
                          <span>{{ t("caption") }}</span>
                        </div>
                        <div>
                          <q-btn
                            class="text-weight-bold"
                            :color="hover ? 'primary' : 'secondary'"
                            label="Continue"
                            no-caps
                          />
                        </div>
                      </div>
                    </div>
                  </q-card-section>
                </q-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </q-responsive>
  </div>
</template>

<script setup>
import { inject, nextTick, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useAppStore } from "src/stores/app";

defineOptions({ name: "SplashScreen" });

const { t } = i18n.global;
const appStore = useAppStore();
const { splashClicked } = storeToRefs(appStore);
const atropos = inject("atropos");
const atroposRef = ref(null);
const hover = ref(false);

const onClick = () => {
  splashClicked.value = true;
};

onMounted(async () => {
  await nextTick();
  const myAtropos = atropos({
    el: atroposRef.value,
    // rotateTouch: false,
    // rotateXMax: 1,
    // rotateYMax: 1,
  });
});
</script>
