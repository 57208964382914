<template>
  <q-layout view="hHh lpR fFf">
    <q-drawer v-model="drawer" bordered side="right" show-if-above>
      <drawer-tabs />
    </q-drawer>
    <q-page-container>
      <router-view />
    </q-page-container>
    <splash-screen v-if="!splashClicked" />
  </q-layout>
</template>

<script setup>
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useAppStore } from "src/stores/app";
import DrawerTabs from "src/components/drawer/DrawerTabs.vue";
import SplashScreen from "src/components/splash/SplashScreen.vue";

defineOptions({ name: "MainLayout" });

const appStore = useAppStore();
const { drawer, splashClicked } = storeToRefs(appStore);
</script>
